export default [
	{
		path: '/performance',
		name: 'Performance',
		component: () => import('@/views/user-performance/index.vue'),
		meta: {
			title: '员工统计',
		},

		children: [
			{
				path: 'information',
				name: 'UserInformation',
				component: () => import('@/views/user-performance/information/index.vue'),
				meta: {
					activePath: '/performance/information',
					title: '员工信息统计',
				},
			},
            {
				path: 'performance',
				name: 'UserPerformance',
				component: () => import('@/views/user-performance/performance/index.vue'),
				meta: {
					activePath: '/performance/performance',
					title: '员工工作量统计',
				},
			},

		],
	},
]
