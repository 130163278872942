export default [
    {
		path: '/mynotes/list',
		name: 'MyNotes',
		component: () => import('@/views/my-notes/index.vue'),
		meta: {
			title: '我的备忘',
            activePath: '/mynotes/list',
		},
	},
]