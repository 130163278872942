import {
	GET_DEPARTMENT_LIST_SUCCESS,
	USER_LIST_SUCCESS,
	GET_DEPARTMENT_TREE_SUCCESS,
	GET_ROLE_LIST_SUCCESS,
	GET_USER_DETAIL_SUCCESS,
	GET_DEPARTMENT_TYPE_SUCCESS,
	GET_DEPARTMENT_DETAIL_SUCCESS,
	GET_ROLE_PAGE_LIST_SUCCESS,
	GET_ROLE_DETAIL_SUCCESS,
	GET_USER_INFO_SUCCESS,
	GET_MENU_TREE_SUCCESS,
	GET_LOGIN_LOG_SUCCESS,
	GET_CUSTOMER_VISIT_SUCCESS,
	GET_MYCUSTOMER_LIST_SUCCESS,
	GET_PUBLICCUSTOMER_LIST_SUCCEES,
	POST_ALLCUSTOMER_LIST_SUCCESS,
	GET_NEWCUSTOMER_LIST_SUCCESS,
	GET_RUBBISH_LIST_SUCCESS,
	GET_ASSGIN_CUSTOMER_LIST_SUCCESS,
	GET_RESOURCE_LIST_SUCCESS,
	GET_INPORT_RECORDS_SUCCESS,
	GET_CUSTOMER_VISIT_REG_SUCCESS,
	GET_WORK_LIST_SUCCESS,
	GET_IMPORT_CUSTOMER_SUCCESS,
	GET_MENU_ROUTERS_SUCCESS,
	GET_CUSTOMER_CHANNEL_LIST_SUCCESS,
	GET_BACKFLOW_LIST_SUCCESS,
	GET_RANK_LIST_SUCCESS,
	GET_COLLECTION_LIST_SUCCESS,
	GET_SIGN_LIST_SUCCESS,
	GET_THEADED_LIST_SUCCESS,
	GET_USER_PERFORMANCE_LIST_SUCCESS,
	GET_NOTICE_SETTING_LIST_SUCCESS,
	GET_CLUE_LIST_SUCCESS,
	GET_CALL_RECORD_LIST_SUCCESS, GET_DIMISSION_LIST_SUCCESS,
	GET_PASSAGEE_CUSTOMER_SUCCESS, GET_FULLCUSTOMER_LIST_SUCCESS,
} from './mutation-type'

const mutations = {
	[USER_LIST_SUCCESS](state, { data }) {
		state.userList = data
	},
	[GET_USER_INFO_SUCCESS](state, { data }) {
		state.userInfo = data
	},
	[GET_DEPARTMENT_TREE_SUCCESS](state, { data }) {
		state.departmentTree = data
	},
	[GET_DEPARTMENT_LIST_SUCCESS](state, { data }) {
		state.departmentList = data
	},
	[GET_ROLE_LIST_SUCCESS](state, { data }) {
		state.roleList = data
	},
	[GET_USER_DETAIL_SUCCESS](state, { data }) {
		state.userDetail = data
	},
	[GET_DEPARTMENT_TYPE_SUCCESS](state, { data }) {
		state.departmentType = data
	},
	[GET_DEPARTMENT_DETAIL_SUCCESS](state, { data }) {
		state.departmentDetail = data
	},
	[GET_ROLE_PAGE_LIST_SUCCESS](state, { data }) {
		state.rolePageList = data
	},
	[GET_ROLE_DETAIL_SUCCESS](state, { data }) {
		state.roleDetail = data
	},
	[GET_MENU_TREE_SUCCESS](state, payload) {
		state.menuTree = payload
	},
	[GET_LOGIN_LOG_SUCCESS](state, { data }) {
		state.loginLogs = data
	},
	[GET_CUSTOMER_VISIT_SUCCESS](state, { data }) {
		state.customerVisit = data
	},
	[GET_MYCUSTOMER_LIST_SUCCESS](state, { data }) {
		state.myCustomerList = data
	},
	[GET_PUBLICCUSTOMER_LIST_SUCCEES](state, { data }) {
		state.publicCustomerList = data
	},
	[POST_ALLCUSTOMER_LIST_SUCCESS](state, { data }) {
		state.allCustomerList = data
	},
	[GET_FULLCUSTOMER_LIST_SUCCESS](state, { data }) {
		state.fullCustomerList = data
	},
	[GET_NEWCUSTOMER_LIST_SUCCESS](state, { data }) {
		state.newCustomerList = data
	},
	[GET_RUBBISH_LIST_SUCCESS](state, { data }) {
		state.rubbishList = data
	},
	[GET_ASSGIN_CUSTOMER_LIST_SUCCESS](state, { data }) {
		state.assginCustomerList = data
	},
	[GET_RESOURCE_LIST_SUCCESS](state, { data }) {
		state.resourceList = data
	},
	[GET_BACKFLOW_LIST_SUCCESS](state, { data }) {
		state.backflowList = data
	},
	[GET_CLUE_LIST_SUCCESS](state, { data }) {
		state.clueList = data
	},
	[GET_INPORT_RECORDS_SUCCESS](state, { data }) {
		state.importRecords = data
	},
	[GET_CUSTOMER_VISIT_REG_SUCCESS](state, { data,totalCount }) {
		state.customerVisitReg = data
		state.customerVisitReg.totalCount = totalCount
	},
	[GET_WORK_LIST_SUCCESS](state, { data }) {
		state.workList = data
	},
	[GET_IMPORT_CUSTOMER_SUCCESS](state, { data }) {
		state.importCustomerList = data
	},
	[GET_MENU_ROUTERS_SUCCESS](state, { data }) {
		state.menuRouters = data
	},
	[GET_CUSTOMER_CHANNEL_LIST_SUCCESS](state, { data }) {
		state.customerChannelList = data
	},
	[GET_RANK_LIST_SUCCESS](state, { data,totalCollection }) {
		state.rankList = data
		state.rankList.totalCollection = totalCollection
	},
	[GET_COLLECTION_LIST_SUCCESS](state, { data }) {
		state.collectionList = data
	},
	[GET_SIGN_LIST_SUCCESS](state, { data }) {
		state.signList = data
	},
	[GET_THEADED_LIST_SUCCESS](state, { data }) {
		state.theadedList = data
	},
	[GET_USER_PERFORMANCE_LIST_SUCCESS](state, { data }) {
		state.userPerformanceList = data
	},
	[GET_NOTICE_SETTING_LIST_SUCCESS](state, { data }) {
		state.noticeSettingList = data
	},
	[GET_CALL_RECORD_LIST_SUCCESS](state, { data }) {
		state.callRecordList = data
	},
	[GET_DIMISSION_LIST_SUCCESS](state, { data }) {
		state.dimissionList = data
	},
	[GET_PASSAGEE_CUSTOMER_SUCCESS](state, { data }) {
		state.passageCustomerList = data
	},
}

export default mutations
