import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/components/layout'
import customer from './customer'
import resginationData from './resgination-data'
import statisticsData from './statistics-data'
import report from './report'
import system from './system'
import clientVisit from './client-visit'
import performance from './perfoemance'
import callRecord from './call-record'
import MyNotes from './my-notes'

const routes = [
	{
		path: '/',
		name: 'Layout',
		component: Layout,
		redirect: { name: 'WorkBench' },
		children: [
			{
				path: '/workbench',
				name: 'WorkBench',
				component: () => import('@/views/workbench'),

				meta: {
					activePath: '/workbench',
					title: '工作台',
				},
			},

			...customer,
			...resginationData,
			...statisticsData,
			...report,
			...system,
			...clientVisit,
			...performance,
			...callRecord,
			...MyNotes,
			{
				path: '/404',
				name: 'Error',
				component: () => import('@/views/error'),
				hidden: true,
			},
		],
	},

	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/login'),
	},
	{
		path: '/customer/visit',
		name: 'CustomerVisits',
		component: () => import('@/views/customer-visit/index.vue'),
		meta: {
			title: '客户来访列表'
		}
	},
    {
		path: '/customer/visit/success',
		name: 'CustomerVisitsSuccess',
		component: () => import('@/views/customer-visit/success.vue'),
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

router.beforeEach((to, from, next) => {
	if(to.meta.title){//判断是否有标题
        document.title = to.meta.title
    }else{
		document.title ="易信网客户客户系统"
	}
	if (
		to.path == '/login' ||
		to.path == '/404' ||
		to.path == '/customer/visit' ||
        to.path == '/customer/visit/success'
	) {
		next()
	} else {
		if (!localStorage.getItem('routes')) {
			next('/login')
		} else if (
			!localStorage.getItem('routes').split(',').includes(to.path)
		) {
			next('/404')
		} else {
			next()
		}
	}
})

export default router
