<template>
  <vue-seamless-scroll :data="newsList" :class-option="optionLeft" class="seamless-warp2" style="color: #fff" v-if="newsList">
    <ul class="item" >
      <li v-for="item in newsList" v-text="item.title" :key="item"></li>
    </ul>
  </vue-seamless-scroll>
</template>
<style lang="scss" scoped>
.seamless-warp2 {
  overflow: hidden;
  height: 25px;
  width: 980px;
  ul.item {
    width: 480px;
    li {
      float: left;
      margin-right: 10px;
    }
  }
}
</style>
<script>
import { onMounted, ref } from "vue";
import vueSeamlessScroll from "vue-seamless-scroll/src";
import { getNotice } from "@/api/method/common";
export default {
  components: { vueSeamlessScroll },

  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2,
      };
    },
  },

  setup() {
    const newsList = ref(null);
    const getNoticeContent = async () => {
      const res = await getNotice();
      if (res.code == 200 && res.data != null) {
        newsList.value = [{title:res.data},{title: res.data}];
      }
    };
    onMounted(() => {
      getNoticeContent();
    });
    
    return {
      newsList,
    };
  },
};
</script>
