import { get, getDelete, post, put } from '../../index.js'
import base from '../../base.js'

//用户管理
export const getUserList = (params) => get(base.getUserList, params)
export const postUser = (params) => post(base.postUser, params)
export const getUserDetail = (params) => get(`${base.getUserDetail}/${params}`)
export const putUser = (params) => put(base.putUser, params)
export const deleteUser = (params) =>
	put(`${base.getUserDelete}/${params}/dimission/status`)
export const putLoginEnable = (params) => put(base.putLoginEnable, params)
export const putLoginDisabled = (params) => put(base.putLoginDisabled, params)
export const putCustomEnable = (params) => put(base.putCustomEnable, params)
export const putCustomDisabled = (params) => put(base.putCustomDisabled, params)
export const batchDeleteUser = (params) =>
	getDelete(base.batchDeleteUser, params)
export const getUserInfo = () => get(base.getUserInfo)
export const getAllUsers = () => get(base.postUser)
export const getDistributeUsers = () => get(base.distributeUsers)
export const resetPasseord = (params) => {
	const { id, password } = params
	return put(`${base.resetPasseord}/${id}/reset?password=${password}`)
}

//部门管理
export const getDepartmentTree = (params) => get(base.getDepartmentTree, params)
export const getDepartmentList = () => get(base.department)
export const getDepartmentType = () => get(base.getDepartmentType)
export const postDepartment = (params) => post(base.department, params)
export const getDepartmentDetail = (params) =>
	get(`${base.department}/${params}`)
export const putDepartment = (params) => put(base.department, params)

export const deleteDepartment = (params) =>
	getDelete(`${base.department}/${params}`)
export const batchDeleteDepartment = (params) =>
	getDelete(base.batchDeleteDepartment, params)

//角色管理
export const getRoleList = () => get(base.getRoleList)
export const getRolePageList = (params) => get(base.getRolePageList, params)
export const postRole = (params) => post(base.role, params)
export const getRoleDetail = (params) => get(`${base.role}/${params}`)
export const putRole = (params) => put(base.role, params)
export const deleteRole = (params) => getDelete(`${base.role}/${params}`)
export const batchDeleteRole = (params) =>
	getDelete(base.batchDeleteRole, params)
export const postRoles = (params) => {
	const { id, menuIds } = params
	return post(`${base.role}/${id}`, menuIds)
}

export const settingDataType = (params) => {
	const { id, dataType } = params
	return post(`${base.role}/${id}/setting?dataType=${dataType}`,null)
}

//菜单管理
export const getMenuTree = (params) => get(base.getMenuTree, params)

//登录日志
export const getLoginLog = (params) => get(base.getLoginLog, params)

//分配规则
export const putRules = (params) => put(base.systemRules, params)
export const getRules = (params) => get(`${base.systemRules}/${params}`)
export const getNoticeList = (params) => get(base.noticeSettingList, params)
export const putNoticeSettingContent = (params) => {
	return put(base.putSettingContent, params)
}
