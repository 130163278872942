export default [
	{
		path: '/myCustomer',
		name: 'Customer',
		component: () => import('@/views/my-customer'),
		meta: {
			title: '我的客户',
		},

		children: [
			{
				path: 'customerData',
				name: 'CustomerData',
				component: () =>
					import('@/views/my-customer/customer-data/index.vue'),
				meta: {
					activePath: '/myCustomer/customerData',
					title: '客户数据',
				},
			},
			{
				path: 'payment',
				name: 'CustomerPayment',
				component: () =>
					import('@/views/my-customer/collection/index.vue'),
				meta: {
					activePath: '/myCustomer/payment',
					title: '回款登记',
				},
			},
            {
				path: 'sign',
				name: 'Sign',
				component: () =>
					import('@/views/my-customer/sign/index.vue'),
				meta: {
					activePath: '/myCustomer/sign',
					title: '签单登记',
				},
			},
			{
				path: 'assgined',
				name: 'CustomerAssgined',
				component: () =>
					import('@/views/my-customer/assgined/index.vue'),
				meta: {
					activePath: '/myCustomer/assgined',
					title: '我的待办',
				},
			},
			{
				path: 'passage',
				name: 'CustomerPassage',
				component: () =>
					import('@/views/my-customer/customer-data/components/passage/index.vue'),
				meta: {
					activePath: '/myCustomer/passage',
					title: '在途客户',
				},
			},
			{
				path: 'public',
				name: 'CustomerPublic',
				component: () =>
					import('@/views/my-customer/customer-data/components/pulic/index.vue'),
				meta: {
					activePath: '/myCustomer/public',
					title: '公海客户',
				},
			},
			{
				path: 'important',
				name: 'CustomerImportant',
				component: () =>
					import('@/views/my-customer/customer-data/components/lock/index.vue'),
				meta: {
					activePath: '/myCustomer/important',
					title: '重要客户',
				},
			},
			{
				path: 'all',
				name: 'CustomerAll',
				component: () =>
					import('@/views/my-customer/customer-data/components/all-customer/index.vue'),
				meta: {
					activePath: '/myCustomer/all',
					title: '全部客户',
				},
			},
			{
				path: 'dimission',
				name: 'CustomerDimission',
				component: () =>
					import('@/views/my-customer/dimission/index.vue'),
				meta: {
					activePath: '/myCustomer/dimission',
					title: '离职客户',
				},
			},
			{
				path: 'search',
				name: 'CustomerSearch',
				component: () => import('@/views/my-customer/search/index.vue'),
				meta: {
					activePath: '/myCustomer/search',
					title: '客户查询管理',
				},
			},
			{
				path: 'rubbish',
				name: 'CustomerRubbish',
				component: () =>
					import('@/views/my-customer/rubbish/index.vue'),
				meta: {
					activePath: '/myCustomer/rubbish',
					title: '垃圾申请管理',
				},
			},
			{
				path: 'newCustomer',
				name: 'NewCustomer',
				component: () =>
					import('@/views/my-customer/newCustomer/index.vue'),
				meta: {
					activePath: '/myCustomer/newCustomer',
					title: '新客户管理',
				},
			},
			{
				path: 'backflow',
				name: 'BackflowCustomer',
				component: () =>
					import('@/views/my-customer/backflow/index.vue'),
				meta: {
					activePath: '/myCustomer/backflow',
					title: '回流池',
				},
			},
            {
				path: 'resource',
				name: 'CustomerResource',
				component: () =>
					import('@/views/my-customer/resource/index.vue'),
				meta: {
					activePath: '/myCustomer/resource',
					title: '资源库',
				},
			},
			{
				path: 'import',
				name: 'CustomerImport',
				component: () =>
					import('@/views/my-customer/import-customer/index.vue'),
				meta: {
					activePath: '/myCustomer/import',
					title: '导入客户',
				},
			},
            {
				path: 'theaded',
				name: 'CustomerTheaded',
				component: () =>
					import('@/views/my-customer/theaded/index.vue'),
				meta: {
					activePath: '/myCustomer/theaded',
					title: '线索库',
				},
			},
			{
				path: 'clue',
				name: 'CustomerClue',
				component: () =>
					import('@/views/my-customer/clue/index.vue'),
				meta: {
					activePath: '/myCustomer/clue',
					title: 'CEO线索库',
				},
			},
		],
	},
]
