<template>
  <router-view />
</template>
<script>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { ElButton, ElMessageBox } from "element-plus";
import { useRoute } from "vue-router";
// import Layout from "./components/layout";
import router from '@/router/index.js'

export default {
  name: "App",
  components: {
    [ElButton.name]: ElButton,
  },
  setup() {
    const websock = ref(null);
    const lockReconnect = ref(false);
    const timeoutnum = ref(null);
    const timeoutObj = ref(null);

    const route = useRoute();
    const { state } = useStore();
    onMounted(() => { });

    onUnmounted(() => {
      websock.value.close();
    });
    const userInfo = computed(() => state.userInfo);
    watch(
      () => state.userInfo,
      () => {
        if (route.path != "/login") {
          initWebSocket();
        }
      }
    );

    const initWebSocket = () => {
      //初始化weosocket
      let ip = process.env.VUE_APP_IP;
      const wsuri = `ws://${ip}/connect/web/` + userInfo.value.id;
      websock.value = new WebSocket(wsuri);
      websock.value.onmessage = websocketonmessage;
      websock.value.onopen = websocketonopen;
      websock.value.onerror = websocketonerror;
      websock.value.onclose = websocketclose;
    };
    const reconnect = () => {
      if (lockReconnect.value) {
        return;
      }
      lockReconnect.value = true;
      timeoutnum.value && clearTimeout(timeoutnum.value);
      timeoutnum.value = setTimeout(function () {
        //新连接
        timeoutObj.value && clearInterval(timeoutObj.value);
        initWebSocket();
        lockReconnect.value = false;
      }, 5000);
    }

    const startHeartbeat = () => {
      console.log("开始发送心跳");
      timeoutObj.value && clearInterval(timeoutObj.value);

      timeoutObj.value = window.setInterval(() => {
        if (websock.value.readyState == 1) {
          websocketsend(JSON.stringify("ping"));
        }
      }, 20000)
    };

    const websocketonopen = () => {
      console.log("连接成功");
      startHeartbeat()
    };
    const websocketonerror = () => {
      console.log("连接错误,正在重新连接");
      reconnect();
    };
    const websocketonmessage = (e) => {
      //数据接收
      const res = JSON.parse(e.data);

      ElMessageBox.confirm(res.msg, "提示", {
        closeOnClickModal: false,
        closeOnPressEscape: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (res.code.indexOf('#') != -1) {
            const arr = res.code.split('#');
            console.log(arr)
            if (arr.length > 0) {
             
             // router.push({ path: '/myCustomer/all', query: { customerId: arr[1],t: new Date().getTime() } })
              router.push({ name: 'CustomerAll', params: { customerId: arr[1],t: new Date().getTime() } })

              
            }
          }
        })
        .catch(() => { });
    };
    const websocketsend = (Data) => {
      //数据发送
      console.log("发送消息:" + Data + "-->" + + new Date().getTime());
      websock.value.send(Data);
    };
    const websocketclose = (e) => {
      console.log("断开连接,正在重新连接", e);
      reconnect();
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/css/variable.scss";
@import url("./assets/css/index.scss");

.el-button--primary {
  border-color: $blue-02 !important;
  background: $blue-02 !important;
}
</style>
