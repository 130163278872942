export default [
    {
		path: '/call/record',
		name: 'CallRecord',
		component: () => import('@/views/call-record/index.vue'),
		meta: {
			title: '通话记录',
            activePath: '/call/record',
		},
	},
]