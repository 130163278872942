export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAILURE = 'USER_LIST_FAILURE'
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS'
export const GET_DEPARTMENT_LIST_SUCCESS = 'GET_DEPARTMENT_LIST_SUCCESS'
export const GET_DEPARTMENT_TREE_SUCCESS = 'GET_DEPARTMENT_TREE_SUCCESS'
export const GET_ROLE_LIST_SUCCESS = 'GET_ROLE_LIST_SUCCESS'
export const GET_USER_DETAIL_SUCCESS = 'GET_USER_DETAIL_SUCCESS'
export const GET_DEPARTMENT_TYPE_SUCCESS = 'GET_DEPARTMENT_TYPE_SUCCESS'
export const GET_DEPARTMENT_DETAIL_SUCCESS = 'GET_DEPARTMENT_DETAIL_SUCCESS'
export const GET_ROLE_PAGE_LIST_SUCCESS = 'GET_ROLE_PAGE_LIST_SUCCESS'
export const GET_ROLE_DETAIL_SUCCESS = 'GET_ROLE_DETAIL_SUCCESS'
export const GET_MENU_TREE_SUCCESS = 'GET_MENU_TREE_SUCCESS'
export const GET_LOGIN_LOG_SUCCESS = 'GET_LOGIN_LOG_SUCCESS'
export const GET_CUSTOMER_VISIT_SUCCESS = 'GET_CUSTOMER_VISIT_SUCCESS'
export const GET_MYCUSTOMER_LIST_SUCCESS = 'GET_MYCUSTOMER_LIST_SUCCESS'
export const GET_PUBLICCUSTOMER_LIST_SUCCEES = 'GET_PUBLICCUSTOMER_LIST_SUCCEES'
export const GET_PASSAGEE_CUSTOMER_SUCCESS = 'GET_PASSAGEE_CUSTOMER_SUCCESS'
export const POST_ALLCUSTOMER_LIST_SUCCESS = 'POST_ALLCUSTOMER_LIST_SUCCESS'
export const GET_FULLCUSTOMER_LIST_SUCCESS = 'GET_FULLCUSTOMER_LIST_SUCCESS'
export const GET_NEWCUSTOMER_LIST_SUCCESS = 'GET_NEWCUSTOMER_LIST_SUCCESS'
export const GET_RUBBISH_LIST_SUCCESS = 'GET_RUBBISH_LIST_SUCCESS'
export const GET_ASSGIN_CUSTOMER_LIST_SUCCESS =
	'GET_ASSGIN_CUSTOMER_LIST_SUCCESS'
export const GET_RESOURCE_LIST_SUCCESS = 'GET_RESOURCE_LIST_SUCCESS'
export const GET_BACKFLOW_LIST_SUCCESS = 'GET_BACKFLOW_LIST_SUCCESS'
export const GET_INPORT_RECORDS_SUCCESS = 'GET_INPORT_RECORDS_SUCCESS'
export const GET_CUSTOMER_VISIT_REG_SUCCESS = 'GET_CUSTOMER_VISIT_REG_SUCCESS'
export const GET_WORK_LIST_SUCCESS = 'GET_WORK_LIST_SUCCESS'
export const GET_IMPORT_CUSTOMER_SUCCESS = 'GET_IMPORT_CUSTOMER_SUCCESS'
export const GET_MENU_ROUTERS_SUCCESS = 'GET_MENU_ROUTERS_SUCCESS'
export const GET_CUSTOMER_CHANNEL_LIST_SUCCESS = 'GET_CUSTOMER_CHANNEL_LIST_SUCCESS'
export const GET_RANK_LIST_SUCCESS = 'GET_RANK_LIST_SUCCESS'
export const GET_COLLECTION_LIST_SUCCESS = 'GET_COLLECTION_LIST_SUCCESS'
export const GET_SIGN_LIST_SUCCESS = 'GET_SIGN_LIST_SUCCESS'
export const GET_THEADED_LIST_SUCCESS = 'GET_THEADED_LIST_SUCCESS'
export const GET_USER_PERFORMANCE_LIST_SUCCESS = 'GET_USER_PERFORMANCE_LIST_SUCCESS'
export const GET_NOTICE_SETTING_LIST_SUCCESS = 'GET_NOTICE_SETTING_LIST_SUCCESS'
export const GET_CLUE_LIST_SUCCESS = 'GET_CLUE_LIST_SUCCESS'
export const GET_CALL_RECORD_LIST_SUCCESS = 'GET_CALL_RECORD_LIST_SUCCESS'
export const GET_DIMISSION_LIST_SUCCESS = 'GET_DIMISSION_LIST_SUCCESS'
