<template>
  <el-aside :width="collapse ? '64px' : '180px'">
    <el-menu
      :default-active="defaultActive"
      class="xinsheng-slider"
      @select="goTopage"
      text-color="#303133"
      active-text-color="#406fff"
      :collapse="collapse"
      uniqueOpened
    >
      <sub-menu :menuList="menuList" />
    </el-menu>

    <div
      :class="collapse ? 'el-icon-s-unfold xinsheng-fold' : 'el-icon-s-fold xinsheng-fold'"
      @click="handleFold"
    />
  </el-aside>
</template>

<script>
import {
  ElMenu,
  ElSubmenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElIcon,
  ElAside,
} from "element-plus";
import { computed, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
// import { menuList } from "./menu";
import SubMenu from "./sub-menu.vue";
import { useStore } from "vuex";

export default {
  name: "Silder",
  components: {
    [ElMenu.name]: ElMenu,
    [ElSubmenu.name]: ElSubmenu,
    [ElMenuItem.name]: ElMenuItem,
    [ElMenuItemGroup.name]: ElMenuItemGroup,
    [ElIcon.name]: ElIcon,
    [ElAside.name]: ElAside,
    SubMenu,
  },

  setup() {
    const { state, dispatch } = useStore();
    const defaultActive = ref("");
    const route = useRoute();
    const router = useRouter();
    // const menuList = reactive(menu);

    defaultActive.value = route.meta.activePath;

    watch(
      () => route.path,
      () => {
        defaultActive.value = route.meta.activePath;
      }
    );
    onMounted(() => {
        menuRouters();
    });
    const goTopage = (index) => {
      router.push(index);
    };

    const collapse = ref(false);
    const handleFold = () => {
      collapse.value = !collapse.value;
    };

    const menuList = computed(() => state.menuRouters);
    const menuRouters = () => {
      dispatch("menuRouters");
    };

    return {
      defaultActive,
      menuList,
      goTopage,
      handleFold,
      collapse,
    };
  },
};
</script>

<style lang="scss" scoped>
.xinsheng-fold {
  position: absolute;
  font-size: 20px;
  bottom: 24px;
  left: 24px;
  cursor: pointer;
}
</style>