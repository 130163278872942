export default [
    {
		path: '/client/visit',
		name: 'ClientVisit',
		component: () => import('@/views/client-visit/index.vue'),
		meta: {
			title: '客户来访列表',
            activePath: '/client/visit',
		},
	},
]