import { get, getDelete, post, put } from '../../index.js'
import base from '../../base.js'

export const getUserByDept = (params) => get(`${base.getUserByDept}/${params}`)
export const getDictionaries = () => get(base.getDictionaries)
export const getRouters = () => get(base.getRouters)
export const getRemind = (id) => get(`${base.remind}/${id}`)
export const postRemind = (params) => post(base.remind, params)
export const putRemind = (params) => put(base.remind, params)
export const deleteRemind = (id) => getDelete(`${base.remind}/${id}`)
export const postCollection = (params) => post(base.collection, params)
export const postSign = (params) => post(base.sign, params)
export const getCustomerSwitch = (params) => get(base.customerSwitch, params)
export const getNotice = (params) => get(base.getNoticeContent, params)
