import { get, post, put } from '../../index.js'
import base from '../../base.js'

export const getCustomerVisitReg = (params) =>
	get(base.customerVisitReg, params)
export const getWorkList = (params) => get(base.workList, params)
export const postWorkReport = (params) => post(base.workReport, params)
export const putWorkReport = (params) => put(base.workReport, params)
export const getWorkReport = (id) => get(`${base.workReport}/${id}`)
export const getRankList = (params) => get(base.getRankList, params)
