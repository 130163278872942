
<template>
  <template
    v-for="item in menuList"
    :key="item"
  >
    <el-submenu
      v-if="item.userMenuModels && item.userMenuModels.length && item.userMenuModels.filter(item => !item.isMenu).length"
      :index="item.url"
    >
      <template #title>
        <i :class="item.icon"></i>
        <span>{{item.menuName}}</span>
      </template>
      <sub-menu :menuList="item.userMenuModels" />
    </el-submenu>
    <template v-else>
      <el-menu-item
        :index="item.url"
        v-if="!item.isMenu"
      >
        <i :class="item.icon"></i>
        <template #title>{{item.menuName}}</template>
      </el-menu-item>
    </template>
  </template>
</template>

<script>
import { ElSubmenu, ElMenuItem, ElIcon } from "element-plus";
export default {
  name: "SubMenu",
  props: ["menuList"],
  components: {
    [ElMenuItem.name]: ElMenuItem,
    [ElSubmenu.name]: ElSubmenu,
    [ElIcon.name]: ElIcon,
  },
  setup() {},
};
</script>

<style>
</style>