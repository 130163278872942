<template>
  <el-container class="xinsheng-container">
    <el-header>
      <div class="left">
        <span class="company-name">易信网</span>
        <span class="separate">|</span>
        {{ userInfo?.nickname }}
        <span class="group-name">{{ userInfo?.roleName }}</span>
        <el-tooltip :content="status" placement="bottom">
          <el-switch
            v-model="status"
            active-color="#406fff"
            active-value="在线"
            inactive-value="离线"
            @change="handleSwitch"
            class="online-switch"
          ></el-switch>
        </el-tooltip>
        <i class="el-icon-message-solid notice-icon"></i>
        <scrollVue />
      </div>
      <div class="right">
        <el-dropdown>
          <span class="code-title">APP下载</span>
          <template #dropdown>
            <img class="code-img" src="../../assets/images/code.png" alt />
          </template>
        </el-dropdown>

        <el-dropdown trigger="click">
          <span class="el-dropdown-link">
            <div class="head-portrait">
              <i class="el-icon-user-solid"></i>
            </div>
            {{ userInfo?.nickname }}
            <i class="el-icon-caret-bottom el-icon--right"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item @click="handleLogout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </el-header>

    <el-container>
      <silder />
      <el-main>
        <div class="breadcrumb-box">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item
              v-for="(breadcrumb, index) in breadcrumbList"
              :key="index"
              >{{ breadcrumb.meta.title }}</el-breadcrumb-item
            >
          </el-breadcrumb>
          <div class="back-btn" @click="gotoBack">返回</div>
        </div>
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import {
  ElContainer,
  ElAside,
  ElHeader,
  ElMain,
  ElIcon,
  ElSwitch,
  ElTooltip,
  ElBadge,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElBreadcrumb,
  ElBreadcrumbItem,
  ElMessage,
} from "element-plus";

import Silder from "../silder";
import scrollVue from "./components/scroll.vue";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { onMounted, watch } from "@vue/runtime-core";
import { useStore } from "vuex";
import { logout } from "@/api/method/login";
import { getCustomerSwitch } from "@/api/method/common";

export default {
  name: "Layout",
  components: {
    [ElContainer.name]: ElContainer,
    [ElHeader.name]: ElHeader,
    [ElMain.name]: ElMain,
    [ElAside.name]: ElAside,
    [ElIcon.name]: ElIcon,
    Silder,
    [ElSwitch.name]: ElSwitch,
    [ElTooltip.name]: ElTooltip,
    [ElBadge.name]: ElBadge,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownMenu.name]: ElDropdownMenu,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElBreadcrumb.name]: ElBreadcrumb,
    [ElBreadcrumbItem.name]: ElBreadcrumbItem,
    [ElDropdown.name]: ElDropdown,
    scrollVue,
  },
  setup() {
    const status = ref("离线");
    const route = useRoute();
    const router = useRouter();
    const { dispatch, state } = useStore();

    watch(
      () => route.path,
      () => {
        getBreadcrumb();
      }
    );

    onMounted(() => {
      getBreadcrumb();
      getUserInfo();
    });
    const breadcrumbList = ref([]);
    const getBreadcrumb = () => {
      breadcrumbList.value = route.matched.filter((item) => item.name != "Layout");
    };
    const userInfo = computed(() => state.userInfo);
    const getUserInfo = async () => {
      await dispatch("userInfo");
      if (userInfo.value.customerSwitch) {
        status.value = "在线";
      } else {
        status.value = "离线";
      }
    };

    const handleSwitch = async (content) => {
      const customerSwitch = content == "在线" ? 1 : 0;
      const res = await getCustomerSwitch({ customerSwitch });
      if (res.code == 200) {
        ElMessage.success(content == "在线" ? "开启成功！" : "关闭成功！");
      } else {
        ElMessage.error(res.msg);
      }
    };
    const gotoBack = () => router.back();

    const handleLogout = async () => {
      const res = await logout();
      if (res.code == 200) {
        localStorage.removeItem("token");
        localStorage.removeItem("routes");
        localStorage.removeItem("buttons");

        router.replace({
          path: "/login",
          query: {
            redirect: route.path,
          },
        });
      }
    };

    return {
      status,
      handleSwitch,
      breadcrumbList,
      userInfo,
      gotoBack,
      handleLogout,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variable.scss";
.xinsheng-container {
  height: 100vh;
  min-width: 1350px;
  .el-header {
    background: $background-color-01;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    padding: 0 20px;
    height: 48px !important;
    .left {
      color: hsla(0, 0%, 100%, 0.7);
      display: flex;
      align-items: center;
      .company-name {
        margin-right: 24px;
        font-size: 20px;
        font-weight: 900;
        color: #fff;
      }
      .separate {
        margin-right: 10px;
        font-size: 16px;
        font-weight: 400;
      }
      .group-name {
        font-size: 14px;
        margin-left: 5px;
      }
      .online-switch {
        margin-left: 24px;
      }
      .el-switch.is-checked::v-deep .el-switch__core {
        border-color: white !important;
      }
      .notice-icon,
      .notice-title {
        color: white;
        margin-left: 10px;
      }
      .notice-block {
        display: flex;
        align-items: center;
        flex: 1;
      }
    }
    .right {
      .badgeBox {
        margin-right: 26px;
        .el-icon-bell {
          font-size: 18px;
          color: white;
        }
      }
      .el-dropdown-link {
        color: white;
        cursor: pointer;
      }
      .head-portrait {
        display: inline-block;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: hsla(0, 0%, 100%, 0.2);
        text-align: center;
        line-height: 28px;
        margin-right: 5px;
      }
      .code-title {
        margin-right: 10px;
        color: white;
        cursor: pointer;
      }
    }
  }

  .xinsheng-warp {
    background: white;
    padding: 20px;
    height: calc(100vh - 148px);
    overflow: auto;
  }
  .breadcrumb-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    line-height: 40px;
    padding-right: 24px;
    font-size: 14px;
    .back-btn {
      padding-left: 18px;
      color: #406fff;
      cursor: pointer;
      background-image: url("../../assets/images/back-btn.png");
      background-position: -2px 11px;
      background-repeat: no-repeat;
      background-size: 16px 16px;
    }
  }
}
.code-img {
  width: 250px;
  height: 250px;
}
</style>

<style lang="scss">
@import "@/assets/css/variable.scss";

.question {
  display: inline-block;
  width: 14px;
  height: 14px;
  text-align: center !important;
  line-height: 12px;
  border-radius: 50%;
  border: 1px solid rgb(94, 94, 102);
  font-size: 12px;
  color: rgb(94, 94, 102);
  font-weight: 400;
  margin-bottom: 0 !important;
  cursor: pointer;
}
.el-menu {
  border: none;
  .el-menu-item,
  .el-submenu__title {
    font-weight: 600;
    padding: 0 40px 0 0;
  }
}
.el-main {
  background: #f5f5fa;
  padding-top: 0;
}
.xinsheng-container {
  .el-button--small {
    font-size: 14px;
    padding: 8px 15px;
    background: #f7f8fa;
    border: none;
  }
  .el-button--primary {
    border-color: $blue-02;
    background: $blue-02;
  }
  .el-button:focus,
  .el-button:hover {
    color: $blue-02;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    color: white;
  }
  .el-date-editor .el-range-input,
  .el-input--small .el-input__inner,
  .el-range-editor--small.el-input__inner,
  .el-textarea__inner {
    background: #f7f8fa;
    border: none;
  }
}
.badgeBox .el-badge__content {
  background: #ff4040;
  border: none;
  height: 14px;
  line-height: 14px;
}
</style>
