export default [
	{
		path: '/statistics',
		name: 'StatisticsData',
		component: () => import('@/views/statistics-data/index.vue'),
		meta: {
			title: '统计数据',
			
		},
        children: [
            {
				path: 'data',
				name: 'Statistics',
				component: () =>
					import('@/views/statistics-data/data/index.vue'),
				meta: {
					title: '渠道质量分析',
					activePath: '/statistics/data',
				},
			},
			{
				path: 'visit',
				name: 'StatisticsVisit',
				component: () =>
					import('@/views/statistics-data/charts/index.vue'),
				meta: {
					title: '来访统计',
					activePath: '/statistics/visit',
				},
			},
        ]
	},
]
