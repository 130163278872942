import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import lang from 'element-plus/lib/locale/lang/zh-cn'
// // import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale'

// 设置语言
locale.use(lang)

createApp(App).use(store).use(router).mount('#app')
