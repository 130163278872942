export default [
	{
		path: '/system',
		name: 'SystemMangement',
		component: () => import('@/views/system-management'),
		meta: {
			title: '系统管理',
		},
		children: [
			{
				path: 'user',
				name: 'SystemUser',
				component: () =>
					import('@/views/system-management/user/index.vue'),
				meta: {
					title: '用户管理',
					activePath: '/system/user',
				},
			},
			{
				path: 'department',
				name: 'SystemDepartment',
				component: () =>
					import('@/views/system-management/department/index.vue'),
				meta: {
					title: '部门管理',
					activePath: '/system/department',
				},
			},
			{
				path: 'role',
				name: 'SystemRole',
				component: () => import('@/views/system-management/role/index.vue'),
				meta: {
					title: '角色管理',
					activePath: '/system/role',
				},
			},
			{
				path: 'distribution',
				name: 'Distribution',
				component: () =>
					import('@/views/system-management/distribution/index.vue'),
				meta: {
					title: '客户分配规则',
					activePath: '/system/distribution',
				},
			},
			{
				path: 'notice',
				name: 'Notice',
				component: () =>
					import('@/views/system-management/notice/index.vue'),
				meta: {
					title: '公告设置',
					activePath: '/system/notice',
				},
			},
			{
				path: 'loginLog',
				name: 'LoginLog',
				component: () => import('@/views/system-management/loginLog/index.vue'),
				meta: {
					title: '员工登录日志',
					activePath: '/system/loginLog',
				},
			},
		],
	},
]
