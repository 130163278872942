import { post, get, put, getDelete } from '../../index.js'
import base from '../../base.js'

export const postCustomer = (params) => post(base.customer, params)
export const putCustomer = (params) => put(base.customer, params)
export const getCustomer = (id) => get(`${base.customer}/${id}`)
export const getMyCustomerList = (params) => get(base.myCustomerList, params)
export const getImportCustomerList = (params) =>
	get(base.importCustomerList, params)

export const getPassageCustomerList = (params) =>
	get(base.passageCustomerList, params)

export const getPublicCustomerList = (params) =>
	get(base.publicCustomerList, params)
export const postAllCustomerList = (params) => get(base.allCustomerList, params)
export const searchCustomerList = (params) => get(base.searchCustomerList, params)
export const getNewCustomerList = (params) => get(base.newCustomerList, params)
export const getRubbishList = (params) => get(base.rubbishList, params)
export const getAssginCustomerList = (params) =>
	get(base.assginCustomerList, params)
export const movetoImportant = (params) => post(base.movetoImportant, params)
export const movetoPassage = (params) => post(base.movetoPassage, params)
export const movetoPublicPool = (params) => post(base.movetoPublicPool, params)
export const movetoRubbish = (params) => post(base.movetoRubbish, params)
export const distribute = (params) => {
	const { userId, customerIds } = params
	return post(`${base.distribute}/${userId}`, customerIds)
}

export const autoDistribute = (params) => {
	return put(base.autoDistribute, params)
}
export const getResourceList = (params) => post(base.resourceList, params)
export const getBackflowListList = (params) => post(base.backflowList, params)
export const getClueListList = (params) => post(base.clueList, params)

export const getImportRecords = (params) => get(base.importRecords, params)
export const getFollowDetail = (id) => get(`${base.followDetail}/${id}`)
export const postCustomerCall = (id) =>
	post(`${base.customerCall}?customerId=${id}`)
export const getCollectionList = (params) => get(base.collectionList, params)
export const getSignList = (params) => get(base.signList, params)
export const deleteCollection = (id) => getDelete(`${base.collection}/${id}`)
export const deleteSign = (id) => getDelete(`${base.sign}/${id}`)
export const getTheadedList = (params) => get(base.threadedList, params)
export const getDimissionList = (params) => get(base.dimissionList, params)
export const putDailyMax = (params) => {
	const { id, dailyMax } = params
	return put(`${base.putUser}/${id}/dailyMax?dailyMax=${dailyMax}`)
}
export const deleteRubbish = (params) => post(base.deleteRubbish, params)

/** 验证当前用户是否为跟进人 */
export const checkCurrUserIsFollower = (params) => get(base.checkUserIsFollower, params)
