export default {
	isLoading: false,
	token: '' || localStorage.getItem('token'),

	userList: null,
	userDetail: null,
	userInfo: null,

	departmentTree: null,
	departmentList: null,
	departmentType: null,
	departmentDetail: null,

	//角色管理
	roleList: null,
	rolePageList: null,
	roleDetail: null,

	//登录日志
	loginLogs: null,

	//菜单管理
	menuTree: null,
	menuRouters: null,

	//来访客户
	customerVisit: null,

	//我的客户
	myCustomerList: null,
	importCustomerList: null,
	publicCustomerList: null,
	allCustomerList: null,
	newCustomerList: null,
	rubbishList: null,
	assginCustomerList: null,
	resourceList: null,
	backflowList: null,
	importRecords: null,
    collectionList: null,
    signList: null,
    theadedList: null,

	//报表
	customerVisitReg: null,
	workList: null,
    rankList: null,

	//渠道
	customerChannelList: null,

	userPerformanceList: null,
	noticeSettingList: null,
	callRecordList: null,
	passageCustomerList:null,
}
