import { get,post } from '../../index.js'
import base from '../../base.js'

export const getCallRecordList = (params) => get(base.callRecordList, params)

/** 添加跟进记录评论 */
export const addFollowRecordCommon=(params) => post(base.followRecordComment,params)

/** 跟进记录评论集合 */
export const getFollowRecordCommentList = (params) => get(base.followRecordCommentList, params)
export const followRecordCommentListByCustomer = (params) => get(base.followRecordCommentListByCustomer, params)

