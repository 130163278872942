export const path = process.env.VUE_APP_BASE_API
const base = {
	/**登录 */
	login: '/admin/api/v1/login',
	/**退出登录 */
	logout: '/admin/api/v1/logout',
	getCode: '/admin/api/v1/code/refresh',
	loginCode: '/authentication/mobile',
	/**工作台 */
	workbench: '/admin/api/v1/crm-workbench',
	/**系统管理-用户管理 */
	getUserList: '/admin/api/v1/ucs-users/page', //用户管理列表
	postUser: '/admin/api/v1/ucs-users', //新增用户
	distributeUsers: '/admin/api/v1/ucs-users/distribute', //可分配用户
	getUserDetail: '/admin/api/v1/ucs-users',
	putUser: '/admin/api/v1/ucs-users',
	getUserDelete: '/admin/api/v1/ucs-users',
	getUserListByDept: '/admin/api/v1/ucs-users/depts',
	putLoginEnable: '/admin/api/v1/ucs-users/status/enable', //一键允许登录
	putLoginDisabled: '/admin/api/v1/ucs-users/status/disabled', //一键禁止登录
	putCustomEnable: '/admin/api/v1/ucs-users/custom/enable', //一键开启接受
	putCustomDisabled: '/admin/api/v1/ucs-users/custom/disabled', //一键禁止接受
	batchDeleteUser: '/admin/api/v1/ucs-users/batch', //批量删除用户
	getUserInfo: '/admin/api/v1/ucs-users/info', //获取当前登录用户信息
	getUserByDept: '/admin/api/v1/ucs-users/depts',
	resetPasseord: '/admin/api/v1/ucs-users', //重置密码
	/**员工统计*/
	userPerformanceList: '/admin/api/v1/ucs-users/statistics/performance',

	/**系统管理-部门管理 */
	getDepartmentTree: '/admin/api/v1/crm-depts/tree', //部门树
	getDepartmentType: '/admin/api/v1/crm-dictionaries/dept', //部门类型
	department: '/admin/api/v1/crm-depts',
	batchDeleteDepartment: '/admin/api/v1/crm-depts/batch', //批量删除部门

	/**系统管理-角色管理 */
	getRoleList: '/admin/api/v1/ucs-roles',
	getRolePageList: '/admin/api/v1/ucs-roles/page',
	role: '/admin/api/v1/ucs-roles',
	batchDeleteRole: '/admin/api/v1/ucs-roles/batch',

	/**系统管理-登录日志 */
	getLoginLog: '/admin/api/v1/crm-login-records/list',

	/**系统管理-分配规则 */
	systemRules: '/admin/api/v1/crm-rules',

	/**菜单管理 */
	getMenuTree: '/admin/api/v1/ucs-menus/tree',
	getRouters: '/admin/api/v1/ucs-menus/routers',

	/**客户来访列表 */
	getCustomerVisit: '/admin/api/v1/crm-customerVisit/list',
	customerVisit: '/admin/api/v1/crm-customerVisit',
	getVisitCharts: '/admin/api/v1/crm-customerVisit/charts',
	/**提醒事项 */
	remind: '/admin/api/v1/crm-memorandums',
	notesList: '/admin/api/v1/crm-memorandums/page',
	/**common 接口 */
	getDictionaries: '/admin/api/v1/crm-dictionaries',
	customerSwitch: '/admin/api/v1/ucs-users/customerSwitch',

	/**我的客户 */
	customer: '/admin/api/v1/customers',
	myCustomerList: '/admin/api/v1/customers/page/my',
	importCustomerList: '/admin/api/v1/customers/page/important',
	passageCustomerList: '/admin/api/v1/customers/page/passage',
	publicCustomerList: '/admin/api/v1/customers/page/public',
	allCustomerList: '/admin/api/v1/customers/page/all',
	searchCustomerList: '/admin/api/v1/customers/page/search',
	newCustomerList: '/admin/api/v1/customers/page/new',
	rubbishList: '/admin/api/v1/customers/page/rubbish',
	assginCustomerList: '/admin/api/v1/customers/page/todo',
	movetoImportant: '/admin/api/v1/customers/movetoImportant',
	movetoPassage: '/admin/api/v1/customers/movetoPassage',
	deleteRubbish: '/admin/api/v1/customers/batchDelete',
	movetoPublicPool: '/admin/api/v1/customers/movetoPublicPool',
	movetoRubbish: '/admin/api/v1/customers/movetoRubbish',
	distribute: '/admin/api/v1/customers/distribute',
	autoDistribute: '/admin/api/v1/customers/autoDistribute',
	resourceList: '/admin/api/v1/customers/page/resource',
	backflowList: '/admin/api/v1/customers/page/backflow',
	clueList: '/admin/api/v1/customers/page/clue',
	downloadTemplate: `${path}/admin/api/v1/crm-export-records/exporttemplate`,
	importRecords: '/admin/api/v1/crm-export-records/page',
	customerCall: '/admin/api/v1/customers/call',
	collectionList: '/admin/api/v1/crm-collection-details/page',
	collection: '/admin/api/v1/crm-collection-details',
	signList: '/admin/api/v1/crm-sign-bills/page',
	sign: '/admin/api/v1/crm-sign-bills',
	threadedList: '/admin/api/v1/customers/page/index',
	dimissionList: '/admin/api/v1/customers/page/dimission',
    checkUserIsFollower:'/admin/api/v1/customers/checkLoginUserIsFollower',

	/**跟进记录 */
	followDetail: '/api/v1/crm-follow-records',
	/** 跟进记录评论 */
	followRecordComment: '/admin/api/v1/crm-follow-records-comment',
	/** 跟进记录评论集合 */
	followRecordCommentList: '/admin/api/v1/crm-follow-records-comment/list',
	followRecordCommentListByCustomer: '/admin/api/v1/crm-follow-records-comment/clist',

	/**报表 */
	customerVisitReg: '/admin/api/v1/crm-customerVisit/registration',
	workList: '/admin/api/v1/crm-work-reports/list',
	workReport: '/admin/api/v1/crm-work-reports',
	getRankList: '/admin/api/v1/crm-collection-details/ranking', //排行榜

	/**渠道 */
	channelList: '/admin/api/v1/crm-channels',
	customerChannelList: '/admin/api/v1/customers/channel',
	noticeSettingList: '/admin/api/v1/crm-notice-settings/page',
	putSettingContent: '/admin/api/v1/crm-notice-settings',
	getNoticeContent: '/admin/api/v1/crm-notice-settings',
	callRecordList: '/admin/api/v1/crm-call-records/page',
}

export default base
