export default [
	{
		path: '/resginationData',
		name: 'ResginationData',
		component: () => import('@/views/resgination-data'),
		meta: {
			title: '离职数据',
            activePath: '/resginationData',
		},
	},
]
