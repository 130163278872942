import { ElMessage } from 'element-plus'
import { getCustomerChannelList } from '../api/method/channel'
import { getRouters } from '../api/method/common'
import {
	getAssginCustomerList,
	getMyCustomerList,
	getNewCustomerList,
	getPublicCustomerList,
	getResourceList,
	getRubbishList,
	getImportRecords,
	postAllCustomerList,
	getImportCustomerList,
	getBackflowListList,
	getCollectionList,
	getSignList,
	getTheadedList,
	getClueListList,
	getDimissionList, getPassageCustomerList, searchCustomerList,
} from '../api/method/customer'
import { getCustomerVisit } from '../api/method/customer-visit'
import {
	getCustomerVisitReg,
	getRankList,
	getWorkList,
} from '../api/method/report'
import {
	getUserList,
	getDepartmentList,
	getDepartmentTree,
	getRoleList,
	getUserDetail,
	getDepartmentType,
	getDepartmentDetail,
	getRolePageList,
	getRoleDetail,
	getUserInfo,
	getMenuTree,
	getLoginLog,
	getNoticeList,
} from '../api/method/system'

import { getUserPerformanceList } from '../api/method/performance'
import { getCallRecordList } from '../api/method/call-record'
import router from '../router'
import {
	GET_DEPARTMENT_LIST_SUCCESS,
	USER_LIST_SUCCESS,
	GET_DEPARTMENT_TREE_SUCCESS,
	GET_ROLE_LIST_SUCCESS,
	GET_USER_DETAIL_SUCCESS,
	GET_DEPARTMENT_TYPE_SUCCESS,
	GET_DEPARTMENT_DETAIL_SUCCESS,
	GET_ROLE_PAGE_LIST_SUCCESS,
	GET_ROLE_DETAIL_SUCCESS,
	GET_USER_INFO_SUCCESS,
	GET_MENU_TREE_SUCCESS,
	GET_LOGIN_LOG_SUCCESS,
	GET_CUSTOMER_VISIT_SUCCESS,
	GET_MYCUSTOMER_LIST_SUCCESS,
	GET_PUBLICCUSTOMER_LIST_SUCCEES,
	POST_ALLCUSTOMER_LIST_SUCCESS,
	GET_NEWCUSTOMER_LIST_SUCCESS,
	GET_RUBBISH_LIST_SUCCESS,
	GET_ASSGIN_CUSTOMER_LIST_SUCCESS,
	GET_RESOURCE_LIST_SUCCESS,
	GET_INPORT_RECORDS_SUCCESS,
	GET_CUSTOMER_VISIT_REG_SUCCESS,
	GET_WORK_LIST_SUCCESS,
	GET_IMPORT_CUSTOMER_SUCCESS,
	GET_MENU_ROUTERS_SUCCESS,
	GET_CUSTOMER_CHANNEL_LIST_SUCCESS,
	GET_BACKFLOW_LIST_SUCCESS,
	GET_RANK_LIST_SUCCESS,
	GET_COLLECTION_LIST_SUCCESS,
	GET_SIGN_LIST_SUCCESS,
	GET_THEADED_LIST_SUCCESS,
	GET_USER_PERFORMANCE_LIST_SUCCESS,
	GET_NOTICE_SETTING_LIST_SUCCESS,
	GET_CLUE_LIST_SUCCESS,
	GET_CALL_RECORD_LIST_SUCCESS,
	GET_DIMISSION_LIST_SUCCESS,
	GET_PASSAGEE_CUSTOMER_SUCCESS, GET_FULLCUSTOMER_LIST_SUCCESS,
} from './mutation-type'

const actions = {
	async userList({ commit }, params) {
		const res = await getUserList(params)

		commit(USER_LIST_SUCCESS, res)
	},
	async userInfo({ commit }) {
		const res = await getUserInfo()
		if (res.code != 200) {
			ElMessage.error(res.msg)
			localStorage.removeItem('token')
			// 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
			setTimeout(() => {
				window.location.href = `/login?redirect=${router.currentRoute.value.fullPath}`
			}, 1000)
		}
		
		commit(GET_USER_INFO_SUCCESS, res)
	},
	async departmentTree({ commit }, params) {
		const res = await getDepartmentTree(params)
		commit(GET_DEPARTMENT_TREE_SUCCESS, res)
	},
	async departmentList({ commit }, params) {
		const res = await getDepartmentList(params)
		commit(GET_DEPARTMENT_LIST_SUCCESS, res)
	},
	async roleList({ commit }, params) {
		const res = await getRoleList(params)
		commit(GET_ROLE_LIST_SUCCESS, res)
	},
	async userDetail({ commit }, params) {
		const res = await getUserDetail(params)
		commit(GET_USER_DETAIL_SUCCESS, res)
	},
	async departmentType({ commit }, params) {
		const res = await getDepartmentType(params)
		commit(GET_DEPARTMENT_TYPE_SUCCESS, res)
	},
	async departmentDetail({ commit }, params) {
		const res = await getDepartmentDetail(params)
		commit(GET_DEPARTMENT_DETAIL_SUCCESS, res)
	},
	async rolePageList({ commit }, params) {
		const res = await getRolePageList(params)
		commit(GET_ROLE_PAGE_LIST_SUCCESS, res)
	},
	async roleDetail({ commit }, params) {
		const res = await getRoleDetail(params)
		commit(GET_ROLE_DETAIL_SUCCESS, res)
	},
	async menuTree({ commit }, params) {
		const res = await getMenuTree(params)
		commit(GET_MENU_TREE_SUCCESS, res)
	},
	async loginLog({ commit }, params) {
		const res = await getLoginLog(params)
		commit(GET_LOGIN_LOG_SUCCESS, res)
	},
	async customerVisit({ commit }, params) {
		const res = await getCustomerVisit(params)
		commit(GET_CUSTOMER_VISIT_SUCCESS, res)
	},
	async myCustomerList({ commit }, params) {
		const res = await getMyCustomerList(params)
		commit(GET_MYCUSTOMER_LIST_SUCCESS, res)
	},
	async importCustomerList({ commit }, params) {
		const res = await getImportCustomerList(params)
		commit(GET_IMPORT_CUSTOMER_SUCCESS, res)
	},

	async passageCustomerList({ commit }, params) {
		const res = await getPassageCustomerList(params)
		commit(GET_PASSAGEE_CUSTOMER_SUCCESS, res)
	},
	async publicCustomerList({ commit }, params) {
		const res = await getPublicCustomerList(params)
		commit(GET_PUBLICCUSTOMER_LIST_SUCCEES, res)
	},
	async allCustomerList({ commit }, params) {
		const res = await postAllCustomerList(params)
		commit(POST_ALLCUSTOMER_LIST_SUCCESS, res)
	},
	async fullCustomerList({ commit }, params) {
		const res = await searchCustomerList(params)
		commit(GET_FULLCUSTOMER_LIST_SUCCESS, res)
	},
	async newCustomerList({ commit }, params) {
		const res = await getNewCustomerList(params)
		commit(GET_NEWCUSTOMER_LIST_SUCCESS, res)
	},
	async rubbishList({ commit }, params) {
		const res = await getRubbishList(params)
		commit(GET_RUBBISH_LIST_SUCCESS, res)
	},
	async assginCustomerList({ commit }, params) {
		const res = await getAssginCustomerList(params)
		commit(GET_ASSGIN_CUSTOMER_LIST_SUCCESS, res)
	},
	async resourceList({ commit }, params) {
		const res = await getResourceList(params)
		commit(GET_RESOURCE_LIST_SUCCESS, res)
	},
	async backflowList({ commit }, params) {
		const res = await getBackflowListList(params)
		commit(GET_BACKFLOW_LIST_SUCCESS, res)
	},
	async clueList({ commit }, params) {
		const res = await getClueListList(params)
		commit(GET_CLUE_LIST_SUCCESS, res)
	},
	async importRecords({ commit }, params) {
		const res = await getImportRecords(params)
		commit(GET_INPORT_RECORDS_SUCCESS, res)
	},
	async customerVisitReg({ commit }, params) {
		const res = await getCustomerVisitReg(params)
		commit(GET_CUSTOMER_VISIT_REG_SUCCESS, res)
	},
	async workList({ commit }, params) {
		const res = await getWorkList(params)
		commit(GET_WORK_LIST_SUCCESS, res)
	},
	async menuRouters({ commit }, params) {
		const res = await getRouters(params)
		commit(GET_MENU_ROUTERS_SUCCESS, res)
	},
	async customerChannelList({ commit }, params) {
		const res = await getCustomerChannelList(params)
		commit(GET_CUSTOMER_CHANNEL_LIST_SUCCESS, res)
	},
	async rankList({ commit }, params) {
		const res = await getRankList(params)
		commit(GET_RANK_LIST_SUCCESS, res)
	},
	async collectionList({ commit }, params) {
		const res = await getCollectionList(params)
		commit(GET_COLLECTION_LIST_SUCCESS, res)
	},
	async signList({ commit }, params) {
		const res = await getSignList(params)
		commit(GET_SIGN_LIST_SUCCESS, res)
	},
	async theadedList({ commit }, params) {
		const res = await getTheadedList(params)
		commit(GET_THEADED_LIST_SUCCESS, res)
	},
	async dimissionList({ commit }, params) {
		const res = await getDimissionList(params)
		commit(GET_DIMISSION_LIST_SUCCESS, res)
	},
	async userPerformanceList({ commit }, params) {
		const res = await getUserPerformanceList(params)
		commit(GET_USER_PERFORMANCE_LIST_SUCCESS, res)
	},
	async noticeSettingList({ commit }, params) {
		const res = await getNoticeList(params)
		commit(GET_NOTICE_SETTING_LIST_SUCCESS, res)
	},
	async callRecordList({ commit }, params) {
		const res = await getCallRecordList(params)		
		commit(GET_CALL_RECORD_LIST_SUCCESS, res)
	},
}

export default actions
