export default [
	{
		path: '/report',
		name: 'Report',
		component: () => import('@/views/report/index.vue'),
		meta: {
			title: '报表',
		},

		children: [
			{
				path: 'visit',
				name: 'VisitRegistration',
				component: () => import('@/views/report/visit-registration/index.vue'),
				meta: {
					activePath: '/report/visit',
					title: '来访登记统计',
				},
			},
            {
				path: 'leaderboard',
				name: 'Leaderboard',
				component: () => import('@/views/report/leaderboard/index.vue'),
				meta: {
					activePath: '/report/leaderboard',
					title: '排行榜',
				},
			},
            {
				path: 'workBriefing',
				name: 'WorkBriefing',
				component: () => import('@/views/report/workBriefing/index.vue'),
				meta: {
					activePath: '/report/workBriefing',
					title: '工作简报',
				},
			},

		],
	},
]
