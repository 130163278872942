import axios from 'axios'
import { ElMessage } from 'element-plus'
import store from '../store'
import router from '../router'
// import qs from 'query-string'

/**
 * 开发环境：dev
 * 测试环境：stage
 * 准生产环境：pre
 * 生产环境：build
 */
// axios.defaults.baseURL = process.env.VUE_APP_BASE_API

// 请求拦截器
axios.interceptors.request.use(
	(config) => {
		// 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
		// 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
		const token = `Bearer ${localStorage.getItem('token')}`
		token && (config.headers.Authorization = token)
		return config
	},
	(error) => {
		return Promise.error(error)
	}
)

// // 响应拦截器
axios.interceptors.response.use(
	(response) =>
		response.status === 200
			? Promise.resolve(response)
			: Promise.reject(response),
	// 服务器状态码不是200的情况
	(error) => {
		const { status, message } = error.response
		if (status) {
			switch (status) {
				// 401: 未登录
				// 未登录则跳转登录页面，并携带当前页面的路径
				// 在登录成功后返回当前页面，这一步需要在登录页操作。
				case 401:
					router.replace({
						path: '/login',
						query: { redirect: router.currentRoute.fullPath },
					})
					break
				// 403 token过期
				// 登录过期对用户进行提示
				// 清除本地token和清空vuex中token对象
				// 跳转登录页面
				case 523:
					ElMessage({
						message: '登录过期，请重新登录',
						duration: 1000,
					})
					// 清除token
					localStorage.removeItem('token')
					store.commit('loginSuccess', null)
					// 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
					setTimeout(() => {
						router.replace({
							path: '/login',
							query: {
								redirect: router.currentRoute.fullPath,
							},
						})
					}, 1000)
					break
				// 404请求不存在
				case 404:
					ElMessage({
						message: '网络请求不存在',
						duration: 1500,
					})
					break
				// 其他错误，直接抛出错误提示
				default:
					ElMessage({
						message,
						duration: 1500,
					})
			}
			return Promise.reject(error.response)
		}
	}
)

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
	return new Promise((resolve, reject) => {
		axios
			.get(url, {
				params,
			})
			.then((res) => {
				resolve(res.data)
			})
			.catch((err) => {
				reject(err.data)
			})
	})
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
	return new Promise((resolve, reject) => {
		axios
			.post(url, params)
			.then((res) => {
				resolve(res.data)
			})
			.catch((err) => {
				reject(err.data)
			})
	})
}
/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put(url, params) {
	return new Promise((resolve, reject) => {
		axios
			.put(url, params)
			.then((res) => {
				resolve(res.data)
			})
			.catch((err) => {
				reject(err.data)
			})
	})
}
/**
 * delete方法，对应delte请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function getDelete(url, data) {
	return new Promise((resolve, reject) => {
		axios
			.delete(url, { data })
			.then((res) => {
				resolve(res.data)
			})
			.catch((err) => {
				reject(err.data)
			})
	})
}

